<template>
  <app-module-view>
    <template slot="body">
      <div>
        <div class="m-b-10">
          <div class="row">
            <div class="col-6">
              <label class="btn btn-secondary" :class="{ 'active': activeTab === 'general' }">
                <input type="radio" v-model="activeTab" value="general" id="activeTab_general">
                {{ $t('feUser.tab.general') }}
              </label>
              <label class="btn btn-secondary" :class="{ 'active': activeTab === 'addresses' }">
                <input type="radio" v-model="activeTab" value="addresses" id="activeTab_addresses">
                {{ $t('feUser.tab.addresses') }}
              </label>
              <label class="btn btn-secondary" :class="{ 'active': activeTab === 'articles' }">
                <input type="radio" v-model="activeTab" value="articles" id="activeTab_articles">
                {{ $t('feUser.tab.articles') }}
              </label>
            </div>
            <div class="text-right col-6">
              <button
                v-if="isSaveButtonVisible"
                type="button"
                class="btn btn-success"
                @click="save"
              >
                <i class="fa fa-save "></i> {{ $t('feUser.save') }}
              </button>
              <app-button-delete v-if="isDeleteButtonVisible" @deleteRecord="deleteRecord" />
              <app-button-close route-name="feUser_list"></app-button-close>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8">
            <div class="card card-body" v-if="activeTab === 'general'">
              <div class="row">
                <div class="col-lg-6">
                  <app-input
                    v-model="feUser.email"
                    @blur="$v.feUser.email.$touch()"
                    :error="$v.feUser.email.$error"
                    id="feUser_email"
                    :label="$t('feUser.email')"
                  >
                  </app-input>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <app-input
                    v-model="feUser.plainPassword"
                    @blur="$v.feUser.plainPassword.$touch()"
                    :error="$v.feUser.plainPassword.$error"
                    id="feUser_plainPassword"
                    :label="$t('feUser.password')"
                  >
                  </app-input>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <app-input
                    v-model="feUser.firstName"
                    id="feUser_firstName"
                    :label="$t('feUser.firstName')"
                  >
                  </app-input>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <app-input
                    v-model="feUser.lastName"
                    id="feUser_lastName"
                    :label="$t('feUser.lastName')"
                  >
                  </app-input>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <input type="hidden" v-model="feUser.id">
                  <app-input
                    v-model="feUser.username"
                    @blur="$v.feUser.username.$touch()"
                    :error="$v.feUser.username.$error"
                    id="feUser_username"
                    :label="$t('feUser.username')"
                  >
                  </app-input>
                </div>
              </div>
            </div>

            <div class="card card-body" v-if="activeTab === 'articles'">
              <h3>{{ $t('feUser.articles') }}</h3>
              <app-fe-user-article-list
                v-if="feUser.id"
                :feUserId="feUser.id"
              >
              </app-fe-user-article-list>
            </div>

            <div class="card card-body" v-if="activeTab === 'addresses'">
              <div class="row">
                <div class="col-lg-10">
                  <h3>{{ $t('feUser.deliveryAddresses') }}</h3>
                </div>
                <div class="col-lg-2">
                  <div class="float-right">
                    <router-link
                      tag="a"
                      class="btn btn-default btn-sm float-right"
                      :to="{ name: 'feUserDeliveryAddress_new', params: { feUserId: feUser.id } }"
                    >
                      {{ $t('feUser.create_new') }}
                    </router-link>
                  </div>
                </div>
              </div>
              <app-fe-user-delivery-address-list
                v-if="feUser.id"
                :feUserId="feUser.id"
              >
              </app-fe-user-delivery-address-list>
            </div>

            <div class="card card-body" v-if="activeTab === 'addresses'">
              <div class="row">
                <div class="col-lg-10">
                  <h3>{{ $t('feUser.billingAddresses') }}</h3>
                </div>
                <div class="col-lg-2">
                  <div class="float-right">
                    <router-link
                      tag="a"
                      class="btn btn-default btn-sm float-right"
                      :to="{ name: 'feUserBillingAddress_new', params: { feUserId: feUser.id } }"
                    >
                      {{ $t('feUser.create_new') }}
                    </router-link>
                  </div>
                </div>
              </div>
              <app-fe-user-billing-address-list
                v-if="feUser.id"
                :feUserId="feUser.id"
              >
              </app-fe-user-billing-address-list>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card card-body" v-if="activeTab === 'general'">
              <h3>{{ $t('feUser.systemInfo') }}</h3>
              <table class="table table-striped table-bordered">
                <tbody>
                <tr>
                  <td>{{ $t('feUser.id') }}</td>
                  <td>{{ feUser.id }}</td>
                </tr>
                <tr>
                  <td>{{ $t('feUser.created_at') }}</td>
                  <td>{{ feUser.createdAt | prettyDateTime }}</td>
                </tr>
                <tr>
                  <td>{{ $t('feUser.modified_at') }}</td>
                  <td>{{ feUser.modifiedAt | prettyDateTime }}</td>
                </tr>
                <tr>
                  <td>{{ $t('feUser.last_login') }}</td>
                  <td>{{ feUser.lastLogin | prettyDateTime }}</td>
                </tr>
                <tr>
                  <td>{{ $t('feUser.masterSite') }}</td>
                  <td>{{ masterSite }}</td>
                </tr>
                <tr>
                  <td>{{ $t('feUser.otherSites') }}</td>
                  <td>{{ otherSites }}</td>
                </tr>
                </tbody>
              </table>
              <div class="row">
                <div class="px-3">
                  <app-checkbox
                    v-model="feUser.enabled"
                    id="feUser_enabled"
                    label="Enabled"
                  >
                  </app-checkbox>
                </div>
              </div>
              <div v-if="feUser.id" class="row">
                <div class="px-3">
                  <app-checkbox
                    v-model="feUser.facebookId"
                    :label="$t('feUser.facebook_login')"
                    disabled
                  >
                  </app-checkbox>
                </div>
              </div>
              <div v-if="feUser.id" class="row">
                <div class="px-3">
                  <app-checkbox
                    v-model="feUser.googleId"
                    :label="$t('feUser.google_login')"
                    disabled
                  >
                  </app-checkbox>
                </div>
              </div>
              <div v-if="feUser.id" class="row">
                <div class="px-3">
                  <app-checkbox
                    v-model="feUser.consent.gdpr"
                    :label="$t('feUser.consent.gdpr')"
                    disabled
                  >
                  </app-checkbox>
                </div>
              </div>
              <div v-if="feUser.id" class="row">
                <div class="px-3">
                  <app-checkbox
                    v-model="feUser.consent.marketing"
                    :label="$t('feUser.consent.marketing')"
                    disabled
                  >
                  </app-checkbox>
                </div>
              </div>
            </div>

            <!--div class="card card-body">
                <h3>{{ $t('feUser.roles') }}</h3>
                <div class="card card-outline-success" v-for="(role, index) in feUser.roles">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-lg-10">
                                <h4 class="m-b-0 text-white">{{ $t('feUser.role_no') }}{{ index+1 }}</h4>
                            </div>
                            <div class="col-lg-2 text-right">
                                <button
                                    class="btn btn-danger btn-sm text-right"
                                    :title="$t('feUser.remove_role')"
                                    @click="removeRole(index)"
                                >
                                    <i class="fas fa-window-close"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <app-input
                                    :value="role"
                                    label="Role *"
                                    disable-form-group
                                    readonly
                                >
                                </app-input>
                            </div>
                        </div>
                    </div>
                </div>
                <button
                    class="btn btn-info m-t-10"
                    title="Add role"
                    @click="addRole"
                >
                    <i class="fa fa-plus"></i> Add role
                </button>
            </div-->

            <!-- div class="card card-body">
                <h3>{{ $t('feUser.permissions') }}</h3>
                <div class="card card-outline-success" v-for="(permission, index) in feUser.permissions">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-lg-10">
                                <h4 class="m-b-0 text-white">{{ $t('feUser.permission_no') }}{{ index+1 }}</h4>
                            </div>
                            <div class="col-lg-2 text-right">
                                <button
                                    class="btn btn-danger btn-sm text-right"
                                    :title="$t('feUser.remove_permission')"
                                    @click="removePermission(index)"
                                >
                                    <i class="fas fa-window-close"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <app-input
                                    :value="permission.id + ' | ' + permission.eventName + ' | ' + permission.strategy"
                                    label="Permission *"
                                    disable-form-group
                                    readonly
                                >
                                </app-input>
                            </div>
                        </div>
                    </div>
                </div>
                <button
                    class="btn btn-info m-t-10"
                    title="Add permission"
                    @click="addPermission"
                >
                    <i class="fa fa-plus"></i> Add permission
                </button>
            </div-->
          </div>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import FeUserModel from '../../model/feUser/FeUser'
import Input from '../form/inputs/Input'
import Checkbox from '../form/Checkbox'
import ButtonDelete from '../shared/ButtonDelete'
import ButtonClose from '../shared/ButtonClose'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import NotifyService from '../../services/NotifyService'
import FeUserDeliveryAddressList from './address/FeUserDeliveryAddressList'
import FeUserBillingAddressList from './address/FeUserBillingAddressList'
import FeUserArticleList from './article/FeUserArticleList'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'FeUserNew',
  data () {
    return {
      feUser: this._.cloneDeep(FeUserModel),
      activeTab: 'general',
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.FRONTEND_USER_PERMISSIONS
    }
  },
  computed: {
    isDeleteButtonVisible () {
      return this.feUser?.id && this.hasPermission(this.requiredPermissions.deleteButton)
    },
    isSaveButtonVisible () {
      return this.hasPermission(this.requiredPermissions.saveButton)
    },
    masterSite () {
      const site = this.$store.getters['site/siteById'](this.feUser.masterSite)
      return (site) ? site.title : this.feUser.masterSite
    },
    otherSites () {
      const enabledSites = this.feUser.enabledSites.filter(siteId => {
        return (siteId !== this.feUser.masterSite)
      }).map(siteId => {
        const site = this.$store.getters['site/siteById'](siteId)
        return (site) ? site.title : siteId
      })
      return enabledSites.toString()
    }
  },
  validations: {
    feUser: {
      username: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(180)
      },
      email: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(180)
      },
      plainPassword: {
        minLength: minLength(5),
        maxLength: maxLength(50)
      }
    }
  },
  components: {
    appModuleView: ModuleView,
    appInput: Input,
    appCheckbox: Checkbox,
    appButtonDelete: ButtonDelete,
    appButtonClose: ButtonClose,
    appFeUserDeliveryAddressList: FeUserDeliveryAddressList,
    appFeUserBillingAddressList: FeUserBillingAddressList,
    appFeUserArticleList: FeUserArticleList
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    prepareFeUserRequest (feUser) {
      delete feUser.modifiedBy
      delete feUser.modifiedAt
      return feUser
    },
    async save () {
      this.$v.$touch()

      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('feUser.error.required_fields'))
        return
      }

      // Create user
      this.$store.dispatch('feUser/create', this.prepareFeUserRequest(this.feUser))
        .then(() => {
          this.feUser = this.$store.getters['feUser/detail']
          if (this.$store.getters['feUser/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('feUser.message.updated_record'))
            this.$router.push('/feUser/' + this.feUser.id + '/edit')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['feUser/error'])
          }
        })
        .catch(error => console.log(error))
    },
    deleteRecord () {
      this.$store.dispatch('feUser/deleteRecord', this.feUser)
        .then(() => {
          if (this.$store.getters['feUser/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('feUser.message.deleted_record'))
            this.$router.push('/feUser')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['feUser/error'])
          }
        })
        .catch(error => console.log(error))
    },
    addRole () {
      const result = {
        data: ''
      }
      this.feUser.roles.push(result)
    },
    removeRole (index) {
      this.feUser.roles.splice(index, 1)
    },
    addPermission () {
      const result = {
        data: ''
      }
      this.feUser.permissions.push(result)
    },
    removePermission (index) {
      this.feUser.permissions.splice(index, 1)
    },
    addSubscription () {
      const result = {
        data: ''
      }
      this.feUser.subscriptions.push(result)
    },
    removeSubscription (index) {
      this.feUser.subscriptions.splice(index, 1)
    }
  }
}
</script>
