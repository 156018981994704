import Store from '../../store'

const getArticleTitle = (articleDocumentId) => {
  let articleTitle = ''

  Store.getters['article/relatedArticles'].forEach(item => {
    if (item.documentId === articleDocumentId) {
      articleTitle = item.field.title
    }
  })
  return articleTitle
}

export default {
  getArticleTitle
}
