<template>
  <app-module-view>
    <template slot="buttons">
      <app-button-create
        v-if="isCreateButtonVisible"
        route-name="feUserArticle_new"
      >
      </app-button-create>
    </template>
    <template slot="body">
      <app-feUser-filter v-if="!feUserId"></app-feUser-filter>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <app-data-table
            :data="feUserArticles"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            :hasEditPermission="isEditButtonVisible"
            @page-change="setPageAndGetRecords"
          >
          </app-data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../ModuleView'
import ButtonCreate from '../../shared/ButtonCreate'
import { mapState } from 'vuex'
import config from '../../../config'
import Preloader from '../../preloader/Preloader'
import FeUserArticleFilter from './FeUserArticleFilter'
import DataTable from '../../shared/DataTable'
import FeUserArticleDetailService from '../../../services/feUser/FeUserArticleDetailService'
import PermissionService from '@/services/PermissionService'

export default {
  name: 'FeUserArticleList',
  props: {
    feUserId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      page: 1,
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.FRONTEND_USER_ARTICLE_PERMISSIONS
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    isCreateButtonVisible () {
      return this.hasPermission(this.requiredPermissions.createButton)
    },
    isEditButtonVisible () {
      return this.hasPermission(this.requiredPermissions.editButton)
    },
    feUserArticles () {
      const feUserArticles = this.$store.getters['feUserArticle/list']

      for (const key in feUserArticles) {
        feUserArticles[key].articleTitle = feUserArticles[key].articleId
      }
      return feUserArticles
    },
    totalCount () {
      return this.$store.getters['feUserArticle/totalCount']
    },
    pageCount () {
      return Math.ceil(this.$store.getters['feUserArticle/totalCount'] / config.defaults.list.limit)
    },
    dataTableConfig () {
      return {
        parent: this,
        fields: {
          id: this.$t('feUser.id'),
          articleId: this.$t('feUser.article_id'),
          articleTitle: this.$t('feUser.article_title'),
          feUserExpanded: this.$t('feUser.user_email')
        },
        actions: {
          edit: 'feUserArticle_edit'
        },
        render: {
          articleTitle: function (data) {
            if (data) {
              return FeUserArticleDetailService.getArticleTitle(data)
            }
          }
        }
      }
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appFeUserFilter: FeUserArticleFilter,
    appDataTable: DataTable,
    appButtonCreate: ButtonCreate
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    getFeUserArticles () {
      if (this.feUserId > 0) {
        this.$store.commit('feUserArticle/setFilter', { feUser: this.feUserId })
      } else {
        this.$store.commit('feUserArticle/setFilter', {})
      }
      this.$store.dispatch('feUserArticle/fetch')
        .then(() => {
          const articleDocumentIds = []

          this.feUserArticles.forEach(item => {
            if (articleDocumentIds.includes(item.articleId) === false) {
              articleDocumentIds.push(item.articleId)
            }
          })
          this.$store.dispatch('article/fetchRelatedArticles', articleDocumentIds)
        })
    },
    setPageAndGetRecords (page) {
      this.page = page
      this.$store.commit('feUserArticle/setPage', page)
      this.getFeUserArticles()
    }
  },
  mounted () {
    this.page = this.$store.getters['feUserArticle/page']
    this.getFeUserArticles()
  }
}
</script>

<style lang="scss" scoped>

</style>
