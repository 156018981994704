<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterFeUserArticles">
          <div class="row">
            <div class="col-lg-1 col-md-6">
              <app-input v-model="filter.id" id="filter_id" :label="$t('feUser.id')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.articleId" id="filter_name" :label="$t('feUser.article_id')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.feUser_email" id="filter_feUser_email"
                         :label="$t('feUser.user_email')"></app-input>
            </div>
            <div class="col-lg-2">
              <button class="btn btn-success pull-right m-t-30" @click="filterFeUserArticles">
                {{ $t('feUser.filter.search') }}
              </button>
              <button class="btn btn-success pull-right m-t-30" data-test="feUserArticle_reset_filter"
                      @click="resetFilters">
                {{ $t('feUser.filter.reset_filter') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Input from '../../form/inputs/Input'
import FeUserArticleFilterModel from '../../../model/feUser/FeUserArticleFilter'

export default {
  name: 'FeUserArticleFilter',
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(FeUserArticleFilterModel)
    }
  },
  components: {
    appInput: Input
  },
  methods: {
    filterFeUserArticles () {
      this.$store.commit('feUserArticle/setPage', 1)
      this.$store.commit('feUserArticle/setFilter', this.filter)
      this.$store.dispatch('feUserArticle/fetch')
    },
    toggleAdvancedFilter () {
      this.advancedFilter = !this.advancedFilter
    },
    resetFilters () {
      this.filter = this._.cloneDeep(FeUserArticleFilterModel)
      this.filterFeUserArticles()
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['feUserArticle/filter']
  }
}
</script>

<style lang="scss">

</style>
